
import React from "react";

const Tags = ({ text, classname }) => {
    return (
        <span className={`current-status-tag ${classname}`}>
            {text}
        </span>
    );
};

export default Tags;
